import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { theme } from '../components/Layout/theme'
import { PageHeader } from '../components/Header/PageHeader'
import { H1, SubTitle, H3 } from '../components/Title'
import { Container, Column, Row } from '../components/Grid'
import { Paragraph, Bold } from '../components/Text'
import { MARKS, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ContentItemIcon } from '../components/ContentList/ContentItemIcon'
import styled from '@emotion/styled'
import { ButtonLink } from '../components/Button'

const ButtonLinkWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const LuminousSoulPage = () => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "soul.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pageContent: contentfulSection(slug: { eq: "luminous-soul" }) {
        content {
          json
        }
      }
    }
  `)

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
  }

  const pageContent = documentToReactComponents(
    data.pageContent.content.json,
    richTextOptions,
  )

  return (
    <Layout>
      <SEO title="Luminous Soul" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.white}
          style={{
            marginBottom: '11px',
            textShadow: '2px 2px 20px rgba(0.5, 0.5, 0.5, 0.4)',
          }}>
          Luminous Soul Healing
        </H1>
        <SubTitle
          textAlign="center"
          color={theme.white}
          style={{ textShadow: '2px 2px 10px rgba(0.5, 0.5, 0.5, 0.4)' }}>
          Een gids voor je ziel...
        </SubTitle>
      </PageHeader>

      <Container>
        <Row style={{ padding: '60px 0 20px' }}>
          <Column display={{ md: 10, xs: 10 }} offset={{ md: 1, xs: 1 }}>
            <H3>Luminous Soul</H3>
            <ContentItemIcon
              key={`soul-duration`}
              label={60}
              type="duration"
              style={{ marginRight: 20 }}
            />
            <ContentItemIcon key={`soul-price`} label={"in de praktijk: 75"} type="price" style={{marginRight: 20}} />
            <ContentItemIcon key={`soul-price-remote`} label={"op afstand: 30" } type="price" />
            <br />
            <br />
            {pageContent}
          </Column>
        </Row>
      </Container>
      <div
        style={{
          backgroundColor: '#FBF4F4',
        }}>
        <Container>
          <Row>
            <Column
              display={{ md: 10 }}
              offset={{ md: 1 }}
              style={{ padding: '30px 0' }}>
              <ButtonLinkWrapper>
                <ButtonLink wide large to="/maak-een-afspraak/">
                  Maak een afspraak
                </ButtonLink>
              </ButtonLinkWrapper>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default LuminousSoulPage
